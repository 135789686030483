<template>
  <b-card
  >
    <b-modal
        hide-footer
        id="modal-prevent-closing"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <ModalAppointment v-if="id_apoi" :id_appoinment="id_apoi"/>
    </b-modal>
    <b-modal
        hide-footer
        no-close-on-backdrop
        id="modal-prevent-closing-treatment"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <ModalCreateTreatment v-if="id_treatment" :id_treatment_props="id_treatment"/>
    </b-modal>
    <b-modal
        hide-footer
        no-close-on-backdrop
        id="modal-prevent-closing-treatment-edit"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <ModalEditTreatment v-if="id_treatment" :id_treatment_props="id_treatment"/>
    </b-modal>
    <div class="m-2">
      <b-overlay
          :show="show"
          no-wrap
      />
      <b-row>
        <b-col cols="8">
          <a v-if="getUser.appointee_id" href="/nueva/tratamiento/doctor">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Agregar Tratamiento
            </b-button>
          </a>
          <a v-else href="/nueva/tratamiento/recepcion">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Agregar Tratamiento
            </b-button>
          </a>
        </b-col>
        <b-col cols="4">
            <b-button
                v-if="!validateCheck"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"
                @click="getDatesValidate"
                variant="primary"

            >
              No validados

            </b-button>
            <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"
                @click="initialize"
                variant="primary"

            >
              Todos
            </b-button>
        </b-col>
        <b-col cols="4" class="mt-2">
          <b-form-group
              label="Fecha"
          >
            <flat-pickr
                v-model="send_date"
                class="form-control"
                :config="{ enableTime: false, dateFormat: 'Y-m-d',locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febreo', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mt-2">
          <b-button
              style="margin-top: 25px"
              @click="getDates"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              aria-controls="sidebar-add-new-event"

              variant="primary"

          >
            Buscar
          </b-button>
        </b-col>
      </b-row>
      <b-row>

        <b-col
            md="2"
            sm="4"
            class="my-1"
        >
          <b-form-group
              class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Paginas</label>
            <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
            md="4"
            sm="8"
            class="my-1"
        >
          <b-form-group
              label="Ordenar"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    -- none --
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
            md="4"
            class="my-1"
        >
          <b-form-group
              label="Filtrar"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Buscar"
              />
              <b-input-group-append>
                <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                >
                  Limpiar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>


        <b-col cols="12">
          <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          >

            <template #cell(actions)="data">
                              <span @click="showModalFiles(data.value.id)" style="margin-right: 10px">
            <feather-icon v-b-tooltip.hover
                          title="Descargar Archivos" icon="FolderIcon" style="color: darkcyan; " size="25"/>
              </span>
              <span v-if="!data.value.validate_appointment">

              </span>
              <span v-else>
                <span v-if="!data.value.validated">

                  <a style="margin-right: 10px" v-if="!data.value.id_treatment"
                     @click=createTreatment(data.value.id)
                              >
                    <feather-icon v-b-tooltip.hover
                                  title="Generar tratamiento" icon="FileTextIcon" style="color: darkblue" size="25"/>
                  </a>
                  <a style="margin-right: 10px" v-else
                     @click=editTreatment(data.value.id_treatment)>
                    <feather-icon v-b-tooltip.hover
                                  title="Modificar Tratamiento" icon="EyeIcon" style="color: darkblue" size="25"/>
                  </a>
                  <span
                      v-if="!data.value.validated && getUser.appointee_id && data.value.id_treatment && !data.value.sale_order || !data.value.validated && getUser.department_id === 6 && data.value.id_treatment && !data.value.sale_order">
                <feather-icon v-b-tooltip.hover @click="validateTreatment(data.value.id, getUser.id)"
                              title="Crear orden" icon="UserCheckIcon" style="color: darkgreen" size="25"/>
                </span>
                  <span
                      v-if="!data.value.validated && getUser.appointee_id && data.value.id_treatment && data.value.sale_order|| !data.value.validated && getUser.department_id === 6 && data.value.id_treatment && data.value.sale_order">
                <feather-icon v-b-tooltip.hover
                              @click="confirmOrderNew(data.value.id, getUser.id, data.value.sale_order, data.value.id_treatment)"
                              title="Confirmar Orden" icon="CheckCircleIcon" style="color: darkgreen" size="25"/>
                </span>
                  <span v-if="data.value.check_exit">
                    <feather-icon v-b-tooltip.hover
                                  title="Estatus Actualizados" icon="LogOutIcon" style="color: darkgreen" size="25"/>
                    </span>
                </span>
                <span v-else>


                  <a style="margin-right: 10px" v-if="data.value.id_treatment"
                               @click=editTreatment(data.value.id_treatment)
                            >
                    <feather-icon v-b-tooltip.hover
                                  title="Ver Tratamiento" icon="EyeIcon" style="color: darkblue" size="25"/>
                  </a>
                  <feather-icon v-b-tooltip.hover v-if="data.value.verify_stock"
                                title="Tratamiento validado" icon="CheckIcon" style="color: darkgreen" size="25"/>
                  <span v-else-if="getUser.appointee_id || getUser.department_id === 6">
                <feather-icon v-b-tooltip.hover @click="validateStock(data.value.stock_id, data.value.id)"
                              title="Abastecer Medicamento" icon="ShoppingCartIcon" style="color: darkgreen" size="25"/>
                  </span>
                      <span v-if="data.value.check_exit">
                <feather-icon v-b-tooltip.hover
                              title="Estatus Actualizados" icon="LogOutIcon" style="color: darkgreen" size="25"/>
                </span>

                </span>
              </span>


            </template>
          </b-table>
        </b-col>

        <b-col
            cols="12"
        >
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
          />
        </b-col>
      </b-row>

    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  VBTooltip,
  BOverlay,
  BModal,
  VBModal

} from 'bootstrap-vue'
import {mapActions, mapGetters} from "vuex";
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {VueGoodTable} from 'vue-good-table'
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import flatPickr from "vue-flatpickr-component";
import ModalCreateTreatment from "@/components/Appointments/ModalCreateTreatment"
import ModalEditTreatment from "@/components/Appointments/ModalEditTreatment"
export default {
  name: "ListTreatment",
  components: {
    VueGoodTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip, BModal,
    VBModal,
    ModalAppointment,flatPickr,ModalCreateTreatment,ModalEditTreatment
  },
  data() {
    return {
      file: null,
      send_date: null,
      file2: null,
      file3: null,
      file4: null,
      id_apoi: null,
      treatment_id: null,
      id_treatment:null,
      date_appointment: null,
      name_pacient_appointment: null,
      perPage: 10,
      show: false,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      sortBy: '',
      validateCheck: false,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id', label: 'id', sortable: true
        },
        {
          key: 'customer', label: 'Paciente', sortable: true
        },
        {
          key: 'nurse', label: 'Enfermera', sortable: true
        },
        {
          key: 'start', label: 'Hora inicio', sortable: true
        },
        {
          key: 'end', label: 'Hora Salida', sortable: true
        },

        {key: 'place', label: 'Sala', sortable: true},
        {key: 'validate', label: 'Validado Por'},
        {
          key: 'actions', label: 'Estatus',
        },
      ],
      items: [],
    }
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    sortOptions() {
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({text: f.label, value: f.key}))

      // Create an options list from our fields
    },
  },
  mounted() {
    this.$root.$on('closeComponent', async () => {
      // your code goes here
      await this.closeModal()
    })
  },
  async created() {

    await this.initialize();
    if (this.$route.query.appointment_id){
        await this.showModalFiles(parseInt(this.$route.query.appointment_id))
    }
    if (this.$route.query.treatment_id){
      await this.editTreatment(parseInt(this.$route.query.treatment_id))
    }

  },
  methods: {
    ...mapActions('treatment', ['findTreatment', 'findImgVerify', 'findImgVerifyModal','validateCount','findTreatmentDate','findTreatmentValidate']),
    ...mapActions('appointment', ['downloadFileAppointment', 'createSaleOrder', 'updateStockOrder', 'downloadFileAppointmentAll', 'createConfirmOrder']),
    async showModalFiles(id) {
      this.show = true
      this.id_apoi = id
      this.show = false
      await this.$bvModal.show('modal-prevent-closing');

      await this.$router.push(`/tratemientos?appointment_id=${this.id_apoi }`).catch(()=>{});
    },
    async resetModal () {

      await this.$router.push("/tratemientos").catch(()=>{});
    },
    async createTreatment(id){
      this.id_treatment = id
      await this.$bvModal.show('modal-prevent-closing-treatment');

    },
    async editTreatment(id){

      this.id_treatment = id
      await this.$bvModal.show('modal-prevent-closing-treatment-edit');
      await this.$router.push(`/tratemientos?treatment_id=${this.id_treatment }`).catch(()=>{});

    },
    async closeModal() {
      this.id_apoi = null
      console.log('wefwe')
      await this.$bvModal.hide('modal-prevent-closing')
      await this.$bvModal.hide('modal-prevent-closing-treatment')
      await this.$bvModal.hide('modal-prevent-closing-treatment-edit')

      if (this.send_date){
        await this.getDates()
      }else if(this.validateCheck){
        await this.getDatesValidate();
      }else{
        await this.initialize();
      }

    },
    async validateStock(id, treatment_id) {

      if (!id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al abastecer verifique con el administrador',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      const response = await this.updateStockOrder({id, treatment_id})
      if (response) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validado Correctamente',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.validateCount()
        if (this.send_date){
          await this.getDates()
        }else{
          await this.initialize()
        }

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al validar',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      }

    },
    async validateTreatment(id, appointee_id) {
      this.show = true
      const send = {
        id,
        appointee_id
      }
      const response = await this.createSaleOrder(send)
      if (response) {

        setTimeout(async () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Validado Correctamente',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.show = false
          if (this.send_date){
            await this.getDates()
          }else{
            await this.initialize()
          }

        }, 4000);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al validar',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
      }
    },
    async confirmOrderNew(id, appointee_id, order_id, treatment) {

      this.show = true
      const send = {
        id,
        appointee_id,
        order_id,
        treatment
      }
      const response = await this.createConfirmOrder(send)
      if (response.status) {
        setTimeout(async () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Orden confirmada',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.show = false
          if (this.send_date){
            await this.getDates()
          }else{
            await this.initialize()
          }
        }, 4000);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.faultString,
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.show = false
      }
    },
    async downloads(id, file, name, partner, file_number) {
      if (!file) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No hay archivo por descargar',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        return
      }
      const [, type] = file.split(';')[0].split('/');
      const send = {
        id,
        type,
        name,
        partner,
        file_number
      }
      await this.downloadFileAppointmentAll(send)
    },
    async getDatesValidate(){
      this.validateCheck = true
      this.items = [];
      let appoint = null
      if (this.getUser.department_id === 13){
        appoint = this.getUser.appointee_id
      }else{
        appoint = null
      }
      const response = await this.findTreatmentValidate(appoint);

      this.totalRows = response.length;
      for (const a of response) {
        if (a.ClinicTreatment) {
          if (a.Sale) {

            if (a.ClinicTreatment.HrEmployeeVali) {
              if (a.Sale.StockPicking){
                const se = a.Sale.StockPicking.name.search("OUT")


                if (se === 3){

                  this.items.push({
                    id: a.id,
                    validate: a.ClinicTreatment.HrEmployeeVali.name,
                    customer: a.ResPartner.name,
                    day: a.appoint_date,
                    nurse: a.ClinicTreatment.HrEmployee.name,
                    start: this.resHoursOdoo(a.app_dt_start),
                    end: this.resHoursOdoo(a.app_dt_stop),
                    place: a.ResPartnerAppointment.name,
                    actions: {
                      id: a.id,
                      date: a.appoint_date,
                      name_pacient: a.ResPartner.name,
                      sale_order: a.Sale.id,
                      stock_id: a.Sale.StockPicking ? a.Sale.StockPicking.id : '',
                      verify_stock: a.Sale.StockPicking ? a.Sale.StockPicking.patient_file_number : '',
                      id_treatment: a.ClinicTreatment.id,
                      nurse: a.ClinicTreatment.charge_nurse,
                      received: a.ClinicTreatment.treatment_received,
                      validated: a.ClinicTreatment.treatment_validated,
                      validate_appointment: a.validate_appointment,
                      delivered: a.ClinicTreatment.treatment_delivered,
                      check_exit: a.ClinicTreatment.check_exit,
                    }
                  })
                }else{

                }
              }else{

              }

            } else if (a.Sale.StockPicking) {

              this.items.push({
                id: a.id,
                customer: a.ResPartner.name,
                validate: null,
                day: a.appoint_date,
                nurse: a.ClinicTreatment.HrEmployee.name,
                start: this.resHoursOdoo(a.app_dt_start),
                end: this.resHoursOdoo(a.app_dt_stop),
                place: a.ResPartnerAppointment.name,
                actions: {
                  id: a.id,
                  date: a.appoint_date,
                  name_pacient: a.ResPartner.name,
                  sale_order: a.Sale.id,
                  stock_id: a.Sale.StockPicking.id,
                  verify_stock: a.Sale.StockPicking.patient_file_number,
                  id_treatment: a.ClinicTreatment.id,
                  nurse: a.ClinicTreatment.charge_nurse,
                  validate_appointment: a.validate_appointment,
                  received: a.ClinicTreatment.treatment_received,
                  validated: a.ClinicTreatment.treatment_validated,
                  delivered: a.ClinicTreatment.treatment_delivered,
                  check_exit: a.ClinicTreatment.check_exit,
                }
              })
            } else {

              this.items.push({
                id: a.id,
                customer: a.ResPartner.name,
                validate: null,
                day: a.appoint_date,
                nurse: a.ClinicTreatment.HrEmployee.name,
                start: this.resHoursOdoo(a.app_dt_start),
                end: this.resHoursOdoo(a.app_dt_stop),
                place: a.ResPartnerAppointment.name,
                actions: {
                  id: a.id,
                  date: a.appoint_date,
                  name_pacient: a.ResPartner.name,
                  sale_order: a.Sale.id,
                  stock_id: 'confirm_order',
                  verify_stock: 'confirm_order',
                  id_treatment: a.ClinicTreatment.id,
                  nurse: a.ClinicTreatment.charge_nurse,
                  validate_appointment: a.validate_appointment,
                  received: a.ClinicTreatment.treatment_received,
                  validated: a.ClinicTreatment.treatment_validated,
                  delivered: a.ClinicTreatment.treatment_delivered,
                  check_exit: a.ClinicTreatment.check_exit,
                }
              })
            }
          } else {

            this.items.push({
              id: a.id,
              customer: a.ResPartner.name,
              validate: null,
              day: a.appoint_date,
              nurse: a.ClinicTreatment.HrEmployee.name,
              start: this.resHoursOdoo(a.app_dt_start),
              end: this.resHoursOdoo(a.app_dt_stop),
              place: a.ResPartnerAppointment.name,
              actions: {
                id: a.id,

                date: a.appoint_date,
                name_pacient: a.ResPartner.name,
                sale_order: null,
                stock_id: null,
                verify_stock: null,
                id_treatment: a.ClinicTreatment.id,
                nurse: a.ClinicTreatment.charge_nurse,
                validate_appointment: a.validate_appointment,
                received: a.ClinicTreatment.treatment_received,
                validated: a.ClinicTreatment.treatment_validated,
                delivered: a.ClinicTreatment.treatment_delivered,
                check_exit: a.ClinicTreatment.check_exit,
              }
            })
          }

        } else {
          this.items.push({
            id: a.id,
            customer: a.ResPartner.name,
            day: a.appoint_date,
            validate: null,
            start: this.resHoursOdoo(a.app_dt_start),
            end: this.resHoursOdoo(a.app_dt_stop),
            nurse: null,
            place: a.ResPartnerAppointment.name,
            actions: {
              id: a.id,
              date: a.appoint_date,
              name_pacient: a.ResPartner.name,
              id_treatment: null,
              sale_order: null,
              nurse: null,
              validate_appointment: a.validate_appointment,
              stock_id: null,
              verify_stock: null,
              received: null,
              validated: null,
              delivered: null,
              check_exit: null
            }
          })
        }
      }
    },
    async getDates(){
      this.validateCheck = false
      this.items = [];
      const response = await this.findTreatmentDate(this.send_date);

      this.totalRows = response.length;
      for (const a of response) {
        if (a.ClinicTreatment) {
          if (a.Sale) {

            if (a.ClinicTreatment.HrEmployeeVali) {
              if (a.Sale.StockPicking){
                const se = a.Sale.StockPicking.name.search("OUT")

                if (se === 3){

                  this.items.push({
                    id: a.id,
                    validate: a.ClinicTreatment.HrEmployeeVali.name,
                    customer: a.ResPartner.name,
                    day: a.appoint_date,
                    nurse: a.ClinicTreatment.HrEmployee.name,
                    start: this.resHoursOdoo(a.app_dt_start),
                    end: this.resHoursOdoo(a.app_dt_stop),
                    place: a.ResPartnerAppointment.name,
                    actions: {
                      id: a.id,
                      date: a.appoint_date,
                      name_pacient: a.ResPartner.name,
                      sale_order: a.Sale.id,
                      stock_id: a.Sale.StockPicking ? a.Sale.StockPicking.id : '',
                      verify_stock: a.Sale.StockPicking ? a.Sale.StockPicking.patient_file_number : '',
                      id_treatment: a.ClinicTreatment.id,
                      nurse: a.ClinicTreatment.charge_nurse,
                      received: a.ClinicTreatment.treatment_received,
                      validated: a.ClinicTreatment.treatment_validated,
                      validate_appointment: a.validate_appointment,
                      delivered: a.ClinicTreatment.treatment_delivered,
                      check_exit: a.ClinicTreatment.check_exit,
                    }
                  })
                }else{

                }
              }else{

              }

            } else if (a.Sale.StockPicking) {

              this.items.push({
                id: a.id,
                customer: a.ResPartner.name,
                validate: null,
                day: a.appoint_date,
                nurse: a.ClinicTreatment.HrEmployee.name,
                start: this.resHoursOdoo(a.app_dt_start),
                end: this.resHoursOdoo(a.app_dt_stop),
                place: a.ResPartnerAppointment.name,
                actions: {
                  id: a.id,
                  date: a.appoint_date,
                  name_pacient: a.ResPartner.name,
                  sale_order: a.Sale.id,
                  stock_id: a.Sale.StockPicking.id,
                  verify_stock: a.Sale.StockPicking.patient_file_number,
                  id_treatment: a.ClinicTreatment.id,
                  nurse: a.ClinicTreatment.charge_nurse,
                  validate_appointment: a.validate_appointment,
                  received: a.ClinicTreatment.treatment_received,
                  validated: a.ClinicTreatment.treatment_validated,
                  delivered: a.ClinicTreatment.treatment_delivered,
                  check_exit: a.ClinicTreatment.check_exit,
                }
              })
            } else {

              this.items.push({
                id: a.id,
                customer: a.ResPartner.name,
                validate: null,
                day: a.appoint_date,
                nurse: a.ClinicTreatment.HrEmployee.name,
                start: this.resHoursOdoo(a.app_dt_start),
                end: this.resHoursOdoo(a.app_dt_stop),
                place: a.ResPartnerAppointment.name,
                actions: {
                  id: a.id,
                  date: a.appoint_date,
                  name_pacient: a.ResPartner.name,
                  sale_order: a.Sale.id,
                  stock_id: 'confirm_order',
                  verify_stock: 'confirm_order',
                  id_treatment: a.ClinicTreatment.id,
                  nurse: a.ClinicTreatment.charge_nurse,
                  validate_appointment: a.validate_appointment,
                  received: a.ClinicTreatment.treatment_received,
                  validated: a.ClinicTreatment.treatment_validated,
                  delivered: a.ClinicTreatment.treatment_delivered,
                  check_exit: a.ClinicTreatment.check_exit,
                }
              })
            }
          } else {

            this.items.push({
              id: a.id,
              customer: a.ResPartner.name,
              validate: null,
              day: a.appoint_date,
              nurse: a.ClinicTreatment.HrEmployee.name,
              start: this.resHoursOdoo(a.app_dt_start),
              end: this.resHoursOdoo(a.app_dt_stop),
              place: a.ResPartnerAppointment.name,
              actions: {
                id: a.id,

                date: a.appoint_date,
                name_pacient: a.ResPartner.name,
                sale_order: null,
                stock_id: null,
                verify_stock: null,
                id_treatment: a.ClinicTreatment.id,
                nurse: a.ClinicTreatment.charge_nurse,
                validate_appointment: a.validate_appointment,
                received: a.ClinicTreatment.treatment_received,
                validated: a.ClinicTreatment.treatment_validated,
                delivered: a.ClinicTreatment.treatment_delivered,
                check_exit: a.ClinicTreatment.check_exit,
              }
            })
          }

        } else {
          this.items.push({
            id: a.id,
            customer: a.ResPartner.name,
            day: a.appoint_date,
            validate: null,
            start: this.resHoursOdoo(a.app_dt_start),
            end: this.resHoursOdoo(a.app_dt_stop),
            nurse: null,
            place: a.ResPartnerAppointment.name,
            actions: {
              id: a.id,
              date: a.appoint_date,
              name_pacient: a.ResPartner.name,
              id_treatment: null,
              sale_order: null,
              nurse: null,
              validate_appointment: a.validate_appointment,
              stock_id: null,
              verify_stock: null,
              received: null,
              validated: null,
              delivered: null,
              check_exit: null
            }
          })
        }
      }
    },
    async initialize() {
      this.validateCheck = false
      this.items = [];
      const response = await this.findTreatment();

      this.totalRows = response.length;
      for (const a of response) {
        if (a.ClinicTreatment) {
          if (a.Sale) {
            if (a.ClinicTreatment.HrEmployeeVali) {
              if (a.Sale.StockPicking){

                const se = a.Sale.StockPicking.name.search("OUT")

                if (se === 3){
                  this.items.push({
                    id: a.id,
                    validate: a.ClinicTreatment.HrEmployeeVali.name,
                    customer: a.ResPartner.name,
                    day: a.appoint_date,
                    nurse: a.ClinicTreatment.HrEmployee.name,
                    start: this.resHoursOdoo(a.app_dt_start),
                    end: this.resHoursOdoo(a.app_dt_stop),
                    place: a.ResPartnerAppointment.name,
                    actions: {
                      id: a.id,
                      date: a.appoint_date,
                      name_pacient: a.ResPartner.name,
                      sale_order: a.Sale.id,
                      stock_id: a.Sale.StockPicking ? a.Sale.StockPicking.id : '',
                      verify_stock: a.Sale.StockPicking ? a.Sale.StockPicking.patient_file_number : '',
                      id_treatment: a.ClinicTreatment.id,
                      nurse: a.ClinicTreatment.charge_nurse,
                      received: a.ClinicTreatment.treatment_received,
                      validated: a.ClinicTreatment.treatment_validated,
                      validate_appointment: a.validate_appointment,
                      delivered: a.ClinicTreatment.treatment_delivered,
                      check_exit: a.ClinicTreatment.check_exit,
                    }
                  })
                }else{

                }
              }else{

              }

            } else if (a.Sale.StockPicking) {

              this.items.push({
                id: a.id,
                customer: a.ResPartner.name,
                validate: null,
                day: a.appoint_date,
                nurse: a.ClinicTreatment.HrEmployee.name,
                start: this.resHoursOdoo(a.app_dt_start),
                end: this.resHoursOdoo(a.app_dt_stop),
                place: a.ResPartnerAppointment.name,
                actions: {
                  id: a.id,
                  date: a.appoint_date,
                  name_pacient: a.ResPartner.name,
                  sale_order: a.Sale.id,
                  stock_id: a.Sale.StockPicking.id,
                  verify_stock: a.Sale.StockPicking.patient_file_number,
                  id_treatment: a.ClinicTreatment.id,
                  nurse: a.ClinicTreatment.charge_nurse,
                  validate_appointment: a.validate_appointment,
                  received: a.ClinicTreatment.treatment_received,
                  validated: a.ClinicTreatment.treatment_validated,
                  delivered: a.ClinicTreatment.treatment_delivered,
                  check_exit: a.ClinicTreatment.check_exit,
                }
              })
            } else {

              this.items.push({
                id: a.id,
                customer: a.ResPartner.name,
                validate: null,
                day: a.appoint_date,
                nurse: a.ClinicTreatment.HrEmployee.name,
                start: this.resHoursOdoo(a.app_dt_start),
                end: this.resHoursOdoo(a.app_dt_stop),
                place: a.ResPartnerAppointment.name,
                actions: {
                  id: a.id,
                  date: a.appoint_date,
                  name_pacient: a.ResPartner.name,
                  sale_order: a.Sale.id,
                  stock_id: 'confirm_order',
                  verify_stock: 'confirm_order',
                  id_treatment: a.ClinicTreatment.id,
                  nurse: a.ClinicTreatment.charge_nurse,
                  validate_appointment: a.validate_appointment,
                  received: a.ClinicTreatment.treatment_received,
                  validated: a.ClinicTreatment.treatment_validated,
                  delivered: a.ClinicTreatment.treatment_delivered,
                  check_exit: a.ClinicTreatment.check_exit,
                }
              })
            }
          } else {

            this.items.push({
              id: a.id,
              customer: a.ResPartner.name,
              validate: null,
              day: a.appoint_date,
              nurse: a.ClinicTreatment.HrEmployee.name,
              start: this.resHoursOdoo(a.app_dt_start),
              end: this.resHoursOdoo(a.app_dt_stop),
              place: a.ResPartnerAppointment.name,
              actions: {
                id: a.id,

                date: a.appoint_date,
                name_pacient: a.ResPartner.name,
                sale_order: null,
                stock_id: null,
                verify_stock: null,
                id_treatment: a.ClinicTreatment.id,
                nurse: a.ClinicTreatment.charge_nurse,
                validate_appointment: a.validate_appointment,
                received: a.ClinicTreatment.treatment_received,
                validated: a.ClinicTreatment.treatment_validated,
                delivered: a.ClinicTreatment.treatment_delivered,
                check_exit: a.ClinicTreatment.check_exit,
              }
            })
          }

        } else {

          this.items.push({
            id: a.id,
            customer: a.ResPartner.name,
            day: a.appoint_date,
            validate: null,
            start: this.resHoursOdoo(a.app_dt_start),
            end: this.resHoursOdoo(a.app_dt_stop),
            nurse: null,
            place: a.ResPartnerAppointment.name,
            actions: {
              id: a.id,
              date: a.appoint_date,
              name_pacient: a.ResPartner.name,
              id_treatment: null,
              sale_order: null,
              nurse: null,
              validate_appointment: a.validate_appointment,
              stock_id: null,
              verify_stock: null,
              received: null,
              validated: null,
              delivered: null,
              check_exit: null
            }
          })
        }
      }
    },
    async onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },


}
</script>

<style scoped>

</style>
